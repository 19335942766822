<template>
  <div class="row mb-5" v-cloak>
    <div class="col-md-12 input-group mb-3">
      <div class="input-group-prepend">
        <span
          class="input-group-text pointerOn bg-white text-danger rounded-0"
          @click="reset"
        >
          <svg
            width="1.3em"
            height="1.3em"
            viewBox="0 0 16 16"
            class="bi bi-x"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </span>
      </div>
      <input
        type="text"
        class="form-control rounded-0"
        placeholder="Vyhľadávanie"
        aria-label="Vyhľadávanie"
        aria-describedby="button-addon2"
        v-model="searchInput"
        @click="userCardBool = false"
      />
    </div>
    <transition name="fade" mode="out-in">
      <div class="col-md-12" v-if="!Loading && !userCardBool" key="1">
        <table class="table d-lg-none d-xl-none">
          <transition-group name="fade" mode="out-in" tag="tbody">
            <tr
              class="blueBpmkText"
              v-for="item in filteredItems.slice(
                startPagePaginator,
                endPagePaginator
              )"
              :key="item.id"
            >
              <th scope="row">
                {{ item.position.name }}
                <br />
                {{ item.title }} {{ item.name }} {{ item.surname }}
                <br />
                {{ item.email }}
                <br />
                {{ item.mobile }}
              </th>
              <td
                class="pointerOn align-middle"
                @click="UserCardFunction(item)"
              >
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  class="bi bi-info-circle"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                  />
                  <path
                    d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"
                  />
                  <circle cx="8" cy="4.5" r="1" />
                </svg>
              </td>
            </tr>
          </transition-group>
        </table>

        <table class="table d-none d-lg-table d-xl-table">
          <thead class="thead blueBpmkBg text-white">
            <tr>
              <th scope="col">Pozícia</th>
              <th scope="col">Meno</th>
              <th scope="col">E-mail</th>
              <th scope="col">Klapka</th>
              <th scope="col">Mobil</th>
              <th scope="col"></th>
            </tr>
          </thead>

          <transition-group
            name="fade"
            mode="out-in"
            tag="tbody"
            v-on:before-enter="beforeEnter"
          >
            <tr
              class="blueBpmkText"
              v-for="item in filteredItems.slice(
                startPagePaginator,
                endPagePaginator
              )"
              :key="item.id"
            >
              <th scope="row">{{ item.position.name }}</th>
              <td>{{ item.title }} {{ item.name }} {{ item.surname }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.phone }}</td>
              <td>{{ item.mobile }}</td>
              <td class="pointerOn" @click="UserCardFunction(item)">
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  class="bi bi-info-circle"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                  />
                  <path
                    d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"
                  />
                  <circle cx="8" cy="4.5" r="1" />
                </svg>
              </td>
            </tr>
          </transition-group>
        </table>
      </div>
      <div class="offset-md-3 col-md-6" v-if="!Loading && userCardBool">
        <div class="card mb-3 blueBpmkText blueBpmkBorder">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="card-body">
                <h5 class="card-title text-center">
                  <b
                    >{{ userID.title }} {{ userID.name }}
                    {{ userID.surname }}</b
                  >
                </h5>
                <p class="card-text"></p>
                <table class="table">
                  <tr class="blueBpmkText">
                    <th scope="row">Úsek :</th>
                    <td>{{ userID.section.name }}</td>
                  </tr>
                  <tr class="blueBpmkText">
                    <th scope="row">Oddelenie:</th>
                    <td>{{ userID.division.name }}</td>
                  </tr>
                  <tr class="blueBpmkText">
                    <th scope="row">Referát:</th>
                    <td>{{ userID.department.name }}</td>
                  </tr>
                  <tr class="blueBpmkText">
                    <th scope="row">Pozícia:</th>
                    <td>{{ userID.position.name }}</td>
                  </tr>
                  <tr class="blueBpmkText">
                    <th scope="row">E-mail:</th>
                    <td>{{ userID.email }}</td>
                  </tr>
                  <tr class="blueBpmkText">
                    <th scope="row">Klapka:</th>
                    <td>{{ userID.phone }}</td>
                  </tr>
                  <tr class="blueBpmkText">
                    <th scope="row">Mobil:</th>
                    <td>{{ userID.mobile }}</td>
                  </tr>
                </table>
                <p class="card-text text-center">
                  <svg
                    width="3em"
                    height="3em"
                    viewBox="0 0 16 16"
                    class="bi bi-info-circle pointerOn"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    @click="userCardBool = false"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                    />
                    <path
                      d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"
                    />
                    <circle cx="8" cy="4.5" r="1" />
                  </svg>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <loading-item v-if="Loading" key="2"></loading-item>
    </transition>

    <footer-item
      :filteredItems="filteredItems.length"
      :startPage="startPage"
      :endPage="allPages"
      @next-page="nextPageTest($event)"
    ></footer-item>
  </div>
</template>

<script>
import axios from "axios";
import LoadingItem from "../components/LoadingItem";
import FooterItem from "../components/FooterItem";
export default {
  props: ["nameApiRequest"],
  components: {
    LoadingItem,
    FooterItem,
  },
  data() {
    return {
      userID: "",
      userCardBool: false,
      Loading: true,
      apiRequest: [],
      searchInput: "",

      rootName: "",

      allPages: 0,
      startPage: 1,
      startPagePaginator: 0,
      endPagePaginator: 10,

      //nameApiRequest: "apartments/section/1",
    };
  },
  mounted() {
    this.BaseRequest();
  },
  methods: {
    ResetPaginator() {
      this.allPages = Math.ceil(this.filteredItems.length / 10);
      this.startPage = 1;
      this.startPagePaginator = 0;
      this.endPagePaginator = 10;
    },
    LoadingFunction(value) {
      setTimeout(() => {
        this.Loading = false;
      }, value);
    },
    nextPageTest(value) {
      this.Loading = true;
      this.LoadingFunction(1000);
      this.startPage = this.startPage + value;
      this.startPagePaginator = this.startPagePaginator + value * 10;
      this.endPagePaginator = this.endPagePaginator + value * 10;
    },
    beforeEnter: function (el) {},
    UserCardFunction($id) {
      // console.log($id);
      this.userID = $id;
      this.userCardBool = true;
    },
    BaseRequest() {
      this.userCardBool = false;
      this.rootName = this.nameApiRequest;
      axios
        .get("https://apiapp.bpmk.sk/api/" + this.nameApiRequest)
        .then(({ data }) => {
          this.apiRequest = data;
          this.allPages = Math.ceil(this.apiRequest.length / 10);
          this.ResetPaginator();
          this.Loading = false;
        })
        .catch((error) => {
          return this.$router.push("/home/404");
        });
    },
    reset() {
      this.userCardBool = false;
      this.searchInput = "";
    },
  },
  watch: {
    nameApiRequest(newValue) {
      this.Loading = true;
      this.BaseRequest();
    },
    filteredItems(newValue) {
      this.Loading = true;
      this.ResetPaginator();
      this.LoadingFunction(1000);
    },
  },
  computed: {
    filteredItems() {
      return this.apiRequest.filter((data) => {
        return (
          data.name.toLowerCase().includes(this.searchInput.toLowerCase()) +
          data.surname.toLowerCase().includes(this.searchInput.toLowerCase()) +
          data.position.name
            .toLowerCase()
            .includes(this.searchInput.toLowerCase()) +
          data.email.toLowerCase().includes(this.searchInput.toLowerCase()) +
          data.phone.toLowerCase().includes(this.searchInput.toLowerCase()) +
          data.mobile.toLowerCase().includes(this.searchInput.toLowerCase())
        );
      });
    },
  },
};
</script>

<style>
</style>
