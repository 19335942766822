<template>
     <div class="col-12 pt-5">
      <div class="half-circle-spinner mx-auto">
        <div class="circle circle-1"></div>
        <div class="circle circle-2"></div>
      </div>
    </div>
</template>

<script>
export default {
  name: "loadingItem",
};
</script>

<style scoped>
</style>
