<template>
  <footer class="container bg-transparent border-0 fixed-bottom">
    <div class="row bg-white blueBpmkText ml-1 mr-1">
      <div class="col-2 col-md-5  text-right" >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="2em"
          height="2em"
          fill="currentColor"
          class="bi bi-arrow-left-short pointerOn"
          viewBox="0 0 16 16"
          @click="nextPaginatorPC(-1)" v-if="startPage!=1"
        >
          <path
            fill-rule="evenodd"
            d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
          />
        </svg>
      </div>
      <div class="col-8 col-md-2 text-center">
        <span class="align-middle">
          {{ startPage }} z {{ endPage }}</span
        >
      </div>
      <div class="col-2 col-md-5  text-left"  >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="2em"
          height="2em"
          fill="currentColor"
          class="bi bi-arrow-right-short pointerOn"
          viewBox="0 0 16 16"
          @click="nextPaginatorPC(+1)" v-if="startPage<endPage"
        >
          <path
            fill-rule="evenodd"
            d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
          />
        </svg>
      </div>
          <div class="col-12 bg-dark text-secondary text-center m-0 p-0">
      © 2020 Bytový podnik mesta Košice, s.r.o.
    </div>
    </div>

  </footer>
</template>

<script>
export default {
  props: ["filteredItems","startPage","endPage"],
  data() {
    return {
    };
  },
  mounted() {

  },
  methods: {
    
    nextPaginatorPC(value) {
      this.$emit("next-page", value);
    },

  },
  watch: {
     
  },
};
</script>

<style scoped lang="scss">
.footer_text_a {
  text-shadow: 0 0.05rem 0.1rem rgba(255, 255, 255, 0.5);
  color: #1070ca;
}
</style>
